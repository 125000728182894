import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import Slider from "./slider"

function InfoSection(fields) {
    return (
        <div className="container -mt-10 mb-10 px-3 md:px-6">
            <div className="hidden lg:flex text-center w-max mx-auto relative">
                <div className="bg-green-400 h-2 w-8 rounded-full mr-4"></div>
                <div className="bg-blue-400 h-2 w-16 rounded-full"></div>
            </div>
            <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
                <h2 className="text-4xl lg:text-5xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-5 mt-12 ">{fields.data.homeFirstSectionTitle}</h2>
                <p className="text-gray-600 text-sm-base lg:text-base leading-normal font-medium text-center mb-0">{fields.data.homeFirstSectionText}</p>
            </div>
            <div className="flex flex-col lg:flex-row w-full lg:w-10/12 xl:w-2/3 mx-auto mt-10 text-center lg:text-left items-center">
                <div className="slider-info w-full lg:w-1/2 mb-6 lg:mb-0">
                    <Slider slides={fields.data.homeAppSliderImages}/>
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="flex flex-col lg:flex-row items-center mb-2 justify-center lg:justify-start">
                        <Img fluid={fields.data.homeFeature1Icon.localFile.childImageSharp.fluid} className="w-10 lg:w-8 lg:mr-3 mb-0" alt={fields.data.homeFeature1Icon.altText} />
                        <h3 className="text-blue-400 text-2xl font-medium mb-0">{fields.data.homeFeature1Title}</h3>
                    </div>
                    <p className="text-gray-600 leading-relaxed text-xs mb-10">{fields.data.homeFeature1Text}</p>
                    <div className="flex flex-col lg:flex-row items-center mb-2 justify-center lg:justify-start">
                        <Img fluid={fields.data.homeFeature2Icon.localFile.childImageSharp.fluid} className="w-10 lg:w-8 mb-0 lg:mr-3" alt={fields.data.homeFeature2Icon.altText}/>
                        <h3 className="text-blue-400 text-2xl font-medium mb-0">{fields.data.homeFeature2Title}</h3>
                    </div>
                    <p className="text-gray-600 leading-relaxed text-xs mb-14">{fields.data.homeFeature2Text}</p>
                    <div className="w-max-content mx-auto lg:ml-0">
                        <Link to={fields.data.buttonLink} className="bg-blue-400 hover:bg-gray-300 text-white text-xs px-12 py-4 rounded-full whitespace-nowrap">{fields.data.buttonText}</Link>
                        <div className="bg-yellow-200 h-2 w-16 lg:w-16 rounded-full mt-8 mx-auto"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoSection;