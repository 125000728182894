import React from "react"
import { Link } from "gatsby"
import Apple from "../../svg/apple-store.svg"
import GoogleStore from "../../svg/google-play-store.svg"

function Register(data) {
  console.log(data)
  return (
    <div className="main-container pb-10 lg:pb-0 lg:pt-10">
      <div className="container pt-1 px-3 md:px-6">
        <div className="w-full md:w-2/3 mx-auto">
          <h2 className="text-blue-400 text-center font-medium text-4xl lg:text-5xl mt-10">
            {data.data.home_cta_footer.homeFooterCtaTitle}
          </h2>
          <p className="text-gray-600 text-sm-base lg:text-base leading-normal font-medium text-center">
            {data.data.home_cta_footer.homeFooterCtaText}
          </p>
        </div>
        <div className="flex mx-auto w-fit-content mt-8 mb-4">
          <div className="mr-10 cursor-pointer">
            <Link to={"/app-store"} className="relative" target="_blank">
              <img src={Apple} className="w-24 h-8 mb-1 mx-auto" alt="apple" />
            </Link>
          </div>
          <div className="cursor-pointer">
            <Link to={"/play-store"} className="relative" target="_blank">
              <img
                src={GoogleStore}
                className="w-24 h-8 mb-1 mx-auto"
                alt="apple"
              />
                </Link>
          </div>
        </div>
      </div>
      {/* <div className="container text-center mt-12 px-3 md:px-6 mb-6">
                <Link to={data.data.homeFooterCtaButtonLink} className="bg-blue-400 hover:bg-gray-300 text-white text-xs px-12 py-4 rounded-full">{data.data.homeFooterCtaButtonText}</Link>
                <div className="bg-yellow-200 h-2 w-16 rounded-full mt-8  mx-auto"></div>
            </div> */}
    </div>
  )
}

export default Register
