import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

function ExtraWork(data) {
    return (
        <div className="relative main-container">
            <div className="relative lg:hidden banner-extra-work z-50 md:-mb-10">
                <div className="relative w-full shape-two">
                    <Img fluid={data.data.extraWorkBannerImage.localFile.childImageSharp.fluid} className="object-cover object-center w-full h-full" alt={data.data.extraWorkBannerImage.altText} />
                </div>
                {data.data.extraWorkBannerImageCenter ?
                    <div className="absolute h-3/4 w-full -bottom-5">
                        <Img fluid={data.data.extraWorkBannerImageCenter.localFile.childImageSharp.fluid} imgStyle={{ width: 'auto', height: '100%', marginLeft: 'auto', left: 'auto', right: 0}} alt={data.data.extraWorkBannerImageCenter.altText} className="w-auto h-full ml-auto" />
                    </div>
                    : ''
                }
            </div>
            <div className="banner-extra-work flex flex-col-reverse lg:flex-row">
                <div className="shape-one bg-blue-200 w-full lg:w-7/12 pt-32 pb-12 lg:pb-24 z-20 -mt-28 lg:mt-0 px-3 md:px-6">
                    <div className="w-full lg:w-7/12 lg:ml-32">
                        <div className="flex">
                            <div className="text-center md:text-left">
                                <h2 className="extra-work-title relative text-white font-medium text-4xl lg:text-5xl mb-8 text-center lg:text-left">
                                    {data.data.extraWorkBannerTitle}
                                </h2>
                                <p className="text-base leading-relaxed lg:leading-normal text-white mb-10 text-center lg:text-left">
                                    {data.data.extraWorkBannerText}
                                </p>
                                <Link to={data.data.extraWorkBannerButtonLink} className="flex w-max bg-white hover:bg-transparent rounded-full px-10 py-3 text-blue-400 hover:text-white text-xs font-semibold mx-auto lg:ml-0 border-2 border-white">
                                    {data.data.extraWorkBannerButtonText}
                                </Link>
                                <div className="flex justify-center lg:justify-start">
                                    <p className="relative extra-work-cta text-white text-xs pt-2 mt-6">
                                        <span className="opacity-60">{data.data.extraWorkBannerCtaText}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-full lg:w-8/12 shape-two lg:-ml-40 h-auto lg:max-h-110 2xl:max-h-100">
                    <Img fluid={data.data.extraWorkBannerImage.localFile.childImageSharp.fluid} className="object-cover object-center w-full h-full" alt={data.data.extraWorkBannerImage.altText} />
                </div>
            </div>
            {data.data.extraWorkBannerImageCenter ?
                <div className="hidden lg:block absolute top-8 h-full right-0 w-full lg:w-8/12 z-20 -mt-28 lg:mt-0">
                    <Img fluid={data.data.extraWorkBannerImageCenter.localFile.childImageSharp.fluid} imgStyle={{ width: 'auto' }} alt={data.data.extraWorkBannerImageCenter.altText} className="w-auto h-full ml-20 mr-auto" />
                </div>
                : ''
            }
        </div>
    );
}

export default ExtraWork;