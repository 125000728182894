import React from "react"
import Img from "gatsby-image"

function Slider(props) {
    return (
        <div className="h-full bg-white shadow-md">
            <div className="h-40 sm:h-40 lg:h-48 w-full">
                <Img fluid={props.img} className="h-full w-full object-center object-cover mb-0" alt={props.altText} />
            </div>
            <div className="  p-4 lg:px-8  pb-0  ">
                <p className="text-gray-600 leading-normal lg:leading-relaxed text-xs lg:text-xs mb-3 italic">{props.text}</p>
                <div className="flex">
                    <Img fluid={props.icon} className="mr-3 mb-0 h-6 w-6" imgStyle={{ width: 'auto', height: 'auto' }} alt={props.role} />
                    <p className="text-xs lg:text-xs leading-tight lg:leading-relaxed">
                        <span className="text-gray-600 font-bold mb-0">{props.name},</span>
                        <span className="text-blue-400 font-semibold mb-0"> {props.role}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Slider;