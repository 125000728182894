import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Banner from "../components/home/banner"
import InfoSection from "../components/home/info-section"
import ExtraWork from "../components/home/extra-work"
import CustomWork from "../components/home/custom-work"
import StaffSlider from "../components/home/staff/staff-slider"
import Register from "../components/home/register"
import InvestorPopup from "../components/common/investorPopup"
import "../css/home.css"

const IndexPage = () => {
  const homeSections = useStaticQuery(graphql`
    query HomeSections {
      allWpPage(filter: { id: { eq: "cG9zdDo5" } }) {
        edges {
          node {
            homeSlider {
              appleStoreLink
              googlePlayLink
              homeSliderText
              homeSliderTitle
              homeSliderImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            Home_first_section {
              buttonLink
              buttonText
              homeFeature1Text
              homeFeature1Title
              homeFeature2Text
              homeFeature2Title
              homeFirstSectionText
              homeFirstSectionTitle
              homeFeature2Icon {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              homeFeature1Icon {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              homeAppSliderImages {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                id
              }
            }
            Home_Banner_Extra_Work {
              extraWorkBannerButtonLink
              extraWorkBannerButtonText
              extraWorkBannerCtaText
              extraWorkBannerTitle
              extraWorkBannerText
              extraWorkBannerImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              extraWorkBannerImageCenter {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            home_services {
              homeServicesText
              homeServicesTitle
            }
            Staff {
              homeStaffTitle
            }
            home_cta_footer {
              homeFooterCtaButtonLink
              homeFooterCtaButtonText
              homeFooterCtaText
              homeFooterCtaTitle
            }
          }
        }
      }
      allWpService(
        filter: { status: { eq: "publish" } }
        sort: { order: ASC, fields: date }
      ) {
        edges {
          node {
            id
            title
            serviceItem {
              serviceText
              serviceImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 250, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                altText
              }
            }
          }
        }
      }
      allWpTeamMember(
        filter: { status: { eq: "publish" } }
        sort: { fields: date, order: ASC }
      ) {
        nodes {
          id
          title
          StaffMember {
            icon {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 40, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            role
            text
          }
        }
      }
    }
  `)

  const [investPopup, setInvestPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => setInvestPopup(true), 7000)
  }, [])

  return (
    <Layout>
      <SEO
        title="Home"
        description={
          homeSections.allWpPage.edges[0].node.Home_first_section
            .homeFirstSectionText
        }
      />
      <Header class="main-container fixed w-full z-50 top-0" />
      
      <Banner data={homeSections.allWpPage.edges[0].node.homeSlider} />
      <InfoSection
        data={homeSections.allWpPage.edges[0].node.Home_first_section}
      />
      <ExtraWork
        data={homeSections.allWpPage.edges[0].node.Home_Banner_Extra_Work}
      />
      <CustomWork
        data={homeSections.allWpPage.edges[0].node.home_services}
        services={homeSections.allWpService.edges}
      />
      <StaffSlider
        data={homeSections.allWpPage.edges[0].node.Staff}
        team={homeSections.allWpTeamMember.nodes}
      />
      <Register data={homeSections.allWpPage.edges[0].node} />
      {investPopup ? <InvestorPopup setInvestPopup={setInvestPopup} /> : <></>}
    </Layout>
  )
}

export default IndexPage
