import React from "react"
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img from "gatsby-image"

SwiperCore.use([Pagination]);

function Slider(data) {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
      watchOverflow={true}
    >
      {data.slides.map((slide) =>
        <SwiperSlide key={slide.id}>
          <div className="md:px-12 pb-6">
            <Img fluid={slide.localFile.childImageSharp.fluid} alt={slide.altText}/>
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  );
}

export default Slider;