import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

function CustomWork(data) {
  return (
    <div className="main-container">
      <div className="container px-3 md:px-6">
        <h2 className="text-blue-400 text-center font-medium text-4xl lg:text-5xl mb-2 lg:mb-4 mt-8 lg:mt-16 lg:leading-snug">
          {data.data.homeServicesTitle}
        </h2>
        <div className="w-full md:w-2/3 mx-auto">
          <p className="text-gray-600 text-sm-base lg:text-base leading-normal font-medium text-center">
            {data.data.homeServicesText}
          </p>
        </div>
      </div>
      {renderServices(data.services)}
      <div className="container text-center mt-8 md:mt-12 px-3 md:px-6">
        <Link
          to="/become-a-tradesperson"
          className="bg-blue-400 hover:bg-gray-300 text-white text-xs px-12 py-4 rounded-full"
        >
          Become a Manus Tradesperson
        </Link>
        <div className="bg-yellow-200 h-2 w-16 rounded-full mt-8  mx-auto"></div>
      </div>
    </div>
  )
}

function renderServices(services) {
  if (Object.keys(services).length > 0) {
    return (
      <div className="container flex flex-col lg:flex-row my-10 lg:my-20 px-3 md:px-6 text-center">
        {services.map(service => (
          <div
            key={service.node.id}
            className="w-full lg:w-1/3 lg:pl-6 xl:pl-10 lg:pr-5 xl:pr-10"
          >
            <Img
              fluid={
                service.node.serviceItem.serviceImage.localFile.childImageSharp
                  .fluid
              }
              imgStyle={{
                width: "100%",
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
                objectPosition: "50% 50%",
                objectFit: "contain",
              }}
              className="h-20 mx-auto mb-3 w-20"
              alt={service.node.serviceItem.serviceImage.altText}
            />
            <h3 className="text-blue-400 text-xl font-medium mb-4">
              {service.node.title}
            </h3>
            <p className="text-gray-600 leading-normal text-xs mb-8">
              {service.node.serviceItem.serviceText}
            </p>
          </div>
        ))}
      </div>
    )
  }
  return
}

export default CustomWork
