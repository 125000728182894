import React, { useState } from "react"
import Slide from "./slide"
import LeftArrow from "../../../svg/left-arrow.svg"
import rightArrow from "../../../svg/right-arrow.svg"

function Slider(data) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleNext = () => {
    setActiveIndex(prevIndex => {
      if (prevIndex === data.team.length - 1) {
        return 0 // Loop back to the first item
      } else {
        return prevIndex + 1
      }
    })
  }

  const handlePrev = () => {
    setActiveIndex(prevIndex => {
      if (prevIndex === 0) {
        return data.team.length - 1 // Go to the last item
      } else {
        return prevIndex - 1
      }
    })
  }

  if (Object.keys(data.team).length > 0) {
    return (
      <div className="mb-28 mt-16 lg:mt-10">
        <h2 className="lg:hidden heading mb-0 pb-2 lg:pb-0 text-4xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center">
          {data.data.homeStaffTitle}
        </h2>
        <div className="relative main-container staff-slider mt-12 lg:mt-0">
          <div className="absolute flex h-full w-full -mt-9">
            <div className="bg-blue-200 shape-one w-9/12"></div>
            <div className="bg-blue-400 shape-two w-8/12 sm:w-9/12 md:w-6/12 xl:w-6/12 z-0 -ml-28 sm:-ml-36 md:-ml-40 lg:-ml-56"></div>
          </div>
          <div className="pt-8 lg:pt-20">
            <div className="container mb-12 px-3 md:px-6">
              <div className="relative flex items-center">
                <h2 className="relative staff-slider-heading hidden lg:block heading text-white font-medium text-5xl mb-0">
                  {data.data.homeStaffTitle}
                </h2>
                <div className="flex md:hidden absolute right-0 my-auto">
                  {activeIndex !== 0 && (
                    <div
                      onClick={handlePrev}
                      className="js-button-prev p-3 bg-green-400 outline-none h-max"
                    >
                      <img
                        src={LeftArrow}
                        className="mb-0 w-5"
                        alt="Left Arrow"
                      />
                    </div>
                  )}
                  {data.team.length - 1 !== activeIndex && (
                    <div
                      onClick={handleNext}
                      className="js-button-next p-3  bg-green-400 ml-5 outline-none h-max"
                    >
                      <img
                        src={rightArrow}
                        className="mb-0 w-5"
                        alt="Right Arrow"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mx-auto flex gap-4 h-full w-full">
              <div className="z-50 xl:mx-32 2xl:mx-40 lg:mx-24 md:mx-12 hidden md:grid grid-cols-3 gap-4">
                {data.team.map((member, index) => (
                  <Slide
                    key={index}
                    img={
                      member.StaffMember.image.localFile.childImageSharp.fluid
                    }
                    icon={
                      member.StaffMember.icon.localFile.childImageSharp.fluid
                    }
                    activedOne={activeIndex}
                    name={member.title}
                    role={member.StaffMember.role}
                    text={member.StaffMember.text}
                    alt={member.StaffMember.image.altText}
                  />
                ))}
              </div>
              <div className="md:hidden mx-4 w-full block z-50">
                <Slide
                  img={
                    data.team[activeIndex].StaffMember.image.localFile
                      .childImageSharp.fluid
                  }
                  icon={
                    data.team[activeIndex].StaffMember.icon.localFile
                      .childImageSharp.fluid
                  }
                  activedOne={activeIndex}
                  name={data.team[activeIndex].title}
                  role={data.team[activeIndex].StaffMember.role}
                  text={data.team[activeIndex].StaffMember.text}
                  alt={data.team[activeIndex].StaffMember.image.altText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default Slider
