import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Apple from "../../svg/apple-store.svg"
import AppleMobileIcon from "../../svg/apple_blue.svg"
import GooglePlay from "../../svg/google-play-store.svg"
import NotifyForm from "./notifyForm"

function Banner(props) {
  return (
    <div className="main-container">
      <div className="relative w-full">
        <div className="w-full main-banner absolute h-full" id="notify">
          <div className="relative triangle-first w-full h-full bg-blue-400 overflow-hidden">
            <div className="relative triangle-second w-full bg-blue-900 h-5/6"></div>
          </div>
        </div>
        <div className="relative lg:hidden lg:w-6/12 xl:w-7/12 z-10 w-full pt-28">
          <Img
            fluid={props.data.homeSliderImage.localFile.childImageSharp.fluid}
            className="w-full lg:-ml-10 2xl:-ml-32"
            alt={props.data.homeSliderImage.altText}
          />
        </div>
        <div className="hidden lg:flex relative z-10 lg:pt-48 lg:pb-36 container lg:right-0 lg:left-0 px-3 md:px-6">
          <div className="hidden lg:block lg:w-6/12 xl:w-7/12">
            <Img
              fluid={props.data.homeSliderImage.localFile.childImageSharp.fluid}
              alt={props.data.homeSliderImage.altText}
              className="w-full lg:-ml-10 2xl:-ml-32"
            />
          </div>
          <div className="w-full lg:w-6/12 xl:w-5/12 flex pt-8 lg:pl-20 xl:pl-10">
            <div>
              <div className="home-banner-title relative">
                <h1
                  className="text-4xl lg:text-4-5xl text-blue-400 lg:text-white font-medium leading-tight text-center lg:text-left mb-2 lg:mb-8"
                  dangerouslySetInnerHTML={{
                    __html: props.data.homeSliderTitle,
                  }}
                ></h1>
              </div>
              <p className="text-blue-200 lg:text-white text-sm lg:text-base leading-snug lg:leading-normal mb-2 lg:mb-12 font-bold lg:font-normal text-center lg:text-left">
                {props.data.homeSliderText}
              </p>
              <NotifyForm />
              <div className="text-white hidden lg:flex items-center mt-6 text-xs">
                Download Now
                <Link to={"/app-store"} className="relative" target="_blank">
                  <img
                    src={Apple}
                    className="w-24 mb-0 mr-2 ml-6"
                    alt="apple"
                  />
                </Link>
                <Link to={"/play-store"} className="relative" target="_blank">
                  <img src={GooglePlay} className="w-24 mb-0" alt="apple" />
                </Link>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden items-center mt-6 justify-center mb-4">
        {/* <img src={AppleMobileIcon} className="w-5 mb-0 mr-2" alt="apple" />
                <div className="text-blue-200 text-xs font-bold pt-1">Coming soon on the <Link to={props.data.appleStoreLink}> Apple Store</Link></div> */}
        <div className="text-blue-200 font-bold flex items-center mt-2 text-xs">
          Download Now
          <Link to={"/app-store"} className="relative">
            <img src={Apple} className="w-24 mb-0 mr-2 ml-3" alt="apple" />
          </Link>
          <Link to={"/play-store"} className="relative">
            <img src={GooglePlay} className="w-24 mb-0" alt="apple" />
          </Link>
        </div>
      </div>
      <div className="bg-blue-400 my-8 lg:hidden text-center py-4">
        
      </div>
      <div className="flex lg:hidden text-center w-max mx-auto relative">
        <div className="bg-green-400 h-2 w-8 rounded-full mr-4"></div>
        <div className="bg-blue-400 h-2 w-16 rounded-full"></div>
      </div>
      <div className="lg:hidden relative container px-3 md:px-6">
        <div className="w-full flex pt-8">
          <div>
            <div className="home-banner-title relative w-fit-content mx-auto">
              <h1 className="text-4xl text-blue-400 font-medium leading-tight text-center mb-2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.data.homeSliderTitle,
                  }}
                ></span>
              </h1>
            </div>
            <p className="text-gray-600 text-sm leading-normal mb-2 font-medium text-center">
              {props.data.homeSliderText}
            </p>
            <NotifyForm />
            <div className="hidden items-center mt-6">
              <img src={Apple} className="w-7 mb-0 mr-2 h-6" alt="apple" />
              <div className="text-sm-base pt-2 text-white">
                <span className="opacity-60">Download Now on the</span>
                <Link to={props.data.appleStoreLink}> Apple Store</Link>
              </div>
              <div className="bg-yellow-200 h-2 w-8 rounded-full ml-4 mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
