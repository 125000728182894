import React from "react"
import { Formik, Field, Form } from "formik"
import * as Yup from 'yup'
import Email from "../../svg/email.svg"

const ContactSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

function NotifyForm() {
    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={async (values) => {
                window.open('https://manusapp.us1.list-manage.com/subscribe/post?u=7588df463c5b6564c89f186c6&id=28e8ad2b6a&EMAIL='+ values.email);
            }}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form>
                    <div className="flex flex-col lg:flex-row bg-white rounded-full px-6 py-3">
                        <div className="w-full flex border lg:border-none border-blue-400 rounded-full lg:rounded-none px-6 lg:px-0 py-3 lg:py-0">
                            <img src={Email} className="w-5 mr-3 mb-0 h-5" alt="email" />
                            <Field
                                id="email"
                                name="email"
                                placeholder="Keep me in the loop, send me the latest news!"
                                type="email"
                                className="w-full outline-none text-xs text-blue-400 placeholder-gray-300"
                            />
                        </div>
                        {errors.email && touched.email && <div className="lg:hidden ml-6 text-sm text-red-500 font-medium mt-2">{errors.email}</div>}

                        <button type="submit" className="w-8/12 lg:w-auto mx-auto text-white lg:text-blue-400 text-xs focus:outline-none font-normal lg:font-semibold bg-blue-400 lg:bg-transparent py-3 lg:py-0 rounded-full lg:rounded-none mt-4 lg:mt-0">SEND</button>
                    </div>
                    <div className="lg:hidden bg-yellow-200 h-2 w-10 rounded-full mt-2 mx-auto"></div>
                    {errors.email && touched.email && <div className="hidden lg:block ml-6 text-xs text-red-500 font-medium">{errors.email}</div>}
                </Form>
            )}
        </Formik>
    );
}

export default NotifyForm